.popup-content {
    margin: auto;
    width: 30%;
    padding: 5px;
    background-color: transparent;
}
.popup-arrow {
    color: white;
}
[role='tooltip'].popup-content {
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
    background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
    background: transparent;
}
.modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border-radius: 16px;
    background-color: white;
    box-shadow: 0 0 8px #282c34;
}

.popup-button {
    color: #282c34;
    padding: 6px 10px;
    border-radius: 16px;
    border: 2px solid #198754;
    background-color: transparent;
    box-shadow: 0 0 8px white;
    letter-spacing: 1px;
    font-size: 14px;
    transition: all .3s ease;
    margin: auto;
}

.popup-button:hover {
    background-color: #198754;
    color: white;
    cursor: pointer;
}

.popup-button:active {
    background-color: #198754;
    color: white;
    transition: 50ms;
}

@media (max-width: 900px) {

    .popup-content {
        margin: auto;
        width: 80%;
        padding: 5px;
        background-color: transparent;
    }

}
