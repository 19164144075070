.SSBoard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2% 0 0 0;
    text-align: center;
    background-color: transparent;
}

.SSBoard h2 {
    margin-bottom: -0.5rem;
}

.SSButtons {
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 1rem;
    padding: 1rem;
    justify-content: center;
}

.SSButtons Button {
    width: 8rem;
    height: 8rem;
    border: 1px solid #282c34;
    background-color: transparent;
    border-radius: 27px;
    box-shadow: 0px 3px 3px 0px #282c34;
}

.SSButtons Button:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: transform .5s;
}

.SSButtons Button:active {

}

.LifeContainer {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding-top: 1rem;
}

.LifeContainerPlayers {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Lifes img {
    width: 1rem;
    height: 1rem;
    margin-left: 0.3rem;
}

.farbe {
    background-image: linear-gradient(#198754, rgba(104, 215, 49, 0.7));
}

.falseDecision {
    -webkit-animation: wiggle 1s, colorRed 1s;
    animation: wiggle 1s, colorRed 1s;
    animation-fill-mode: forwards;
}

@-webkit-keyframes colorRed {
    0% { background-color: inherit; }
    40% { background-color: red; }
    100% { background-color: red; }
}

@keyframes colorRed {
    0% { background-color: inherit; }
    40% { background-color: red; }
    100% { background-color: red; }
}

@-webkit-keyframes wiggle {
    0% {transform: rotate(0deg);}
    12% {transform: rotate(-10deg)}
    25% {transform: rotate(10deg);}
    50% {transform: rotate(-10deg);}
    75% {transform: rotate(5deg);}
    100% {transform: rotate(0deg);}
}

@keyframes wiggle {
    0% {transform: rotate(0deg);}
    12% {transform: rotate(-10deg)}
    25% {transform: rotate(10deg);}
    50% {transform: rotate(-10deg);}
    75% {transform: rotate(5deg);}
    100% {transform: rotate(0deg);}
}


@media (max-width: 900px) {

    .SSBoard {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20% 0 0 0;
        text-align: center;
        background-color: transparent;
    }

    .SSButtons {
        display: grid;
        grid-template-columns: repeat(3, auto);
        gap: 1rem;
        padding: 2rem;
        justify-content: center;
    }

    .SSButtons Button {
        width: 25vw;
        height: 25vw;
        border: 1px solid #282c34;
        background-color: transparent;
        border-radius: 27px;
        box-shadow: 0px 3px 3px 0px #282c34;
    }

}

