.about-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3%;
    text-align: center;
    background-color: transparent;
}

.about-container img {
    width: 10rem;
    height: auto;
    border-radius: 16px;
}

.about-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 26rem;
    text-align: justify;
    margin-bottom: 0rem;
}

.creator {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.creator h3 {
    margin-top: 1rem;
    margin-bottom: 0rem;
}

.creator-names {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.mail-contact {
    font-weight: bold;
}

@media (max-width: 900px) {

    .about-info-container {
        width: 12rem;
    }

}


