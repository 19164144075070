.footer-container footer {
    background-color: #343a40;
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    justify-items: center;
    flex-direction: row;
    padding: 0px 4rem 0px 0rem;
}

@media (max-width: 900px) {

    .footer-container footer {
        background-color: #343a40;
        color: white;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        justify-items: right;
        flex-direction: row;
        padding: 0px 1rem 0px 0rem;
        font-size: xx-small;
    }

}