.highscoreTable-container {
    display: flex;
    justify-content: center;
    border-collapse: separate;
    margin-bottom: 3rem;
}

tbody tr:nth-child(odd) {
    background-color: whitesmoke;
}

th {
    font-size: x-large;
    padding: 0.4rem;
}

td {
    font-size: larger;
    padding: 0.2rem;
}

.loader {
    color: green;
    width: 2rem;
    height: 2rem;
    position: relative;
    margin-top: 2rem;
}

.loader:before, .loader:after {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 10px solid transparent;
    border-top-color: green;
}

.loader:before {
    z-index: 100;
    animation: spin 1s infinite;
}

.loader:after {
    border: 10px solid #ccc;
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}




