.TTTBoard {
    position: absolute;
    left: 50%;
    top: 52%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.TTTBoard h2 {
    margin-bottom: -0.5rem;
}

.TTTButtons {
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 0;
    padding: 2rem;
    justify-content: center;
}

.TTTButtons Button {
    width: 9rem;
    height: 9rem;
    border: 1px solid black;
}

.TTTButtons Button:hover {
    cursor: pointer;
    background-color: whitesmoke;
}

.BLANK {
    background-color: transparent;
}

.X {
    background-color: transparent;
    background-image: url("../resources/TicTacToe/RickHead.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.O {
    background-color: transparent;
    background-image: url("../resources/TicTacToe/MortyHead.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

@media (max-width: 900px) {

    .TTTBoard {
        position: absolute;
        left: 50%;
        top: 52%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .TTTButtons Button {
        width: 27.5vw;
        height: 15vh;
        border: 1px solid black;
    }

}