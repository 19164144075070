.highscores-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;
    margin-top: 3%;

    text-align: center;

    background-color: transparent;
}

.buttonBar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    list-style: none;
    gap: 3rem;
}

.buttonBar Button {
    padding: 6px 10px;
    border: 2px solid #198754;
    border-radius: 10px;
    color: #11623c;
    background-color: transparent;
    box-shadow: 0 0 8px white;
    letter-spacing: 1px;
    font-size: large;
    transition: all .2s ease;
}

.buttonBar Button:hover {
    color: white;
    background-color: #198754;
    transition: all .2s ease;
}

.buttonBar Button:active {
    transform: scale(0.95);
}

.buttonBar Button:focus {
    color: white;
    background-color: #198754;
}

@media (max-width: 900px) {

    .buttonBar {
        flex-direction: column;
        gap: 1rem;
    }

}