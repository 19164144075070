.page-nav {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
    height: 4rem;
    color: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 4rem 0px 4rem;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.3);
    background-color: #198754;
}

.page-nav h1 a {
    color: whitesmoke;
    text-decoration: none;
}

.page-nav h1 a:hover {
    color: white;
}

.page-nav ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.page-nav li {
    margin: 0 10px;
}

.page-nav button {
    color: whitesmoke;
    background-color: transparent;
    border: none;
    font-weight: 1000;
    font-size: x-large;
    transition: transform 250ms;
}

.page-nav button:hover {
    cursor: pointer;
    transform: translateY(-1px);
    transition: transform 250ms;
    color: white;
}

.page-nav button:active {
    transform: translateY(-2px);
    transition: transform 34ms;
}

@media (max-width: 900px) {
    .page-nav {
        position: sticky;
        top: 0;
        z-index: 10;
        height: 4rem;
        background-color: #282c34;
        color: whitesmoke;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        gap: 2rem;
        padding: 0px 4rem 0px 4rem;
        box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.3);
        background-color: #198754;
    }

    .page-nav h1 a {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: large;
    }

    .menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .menu li {
        list-style-type: none;
    }

    .page-nav button {
        color: whitesmoke;
        background-color: transparent;
        border: none;
        font-size: 0.8rem;
        transition: transform 250ms;
    }

    .page-nav button:hover {
        cursor: pointer;
        transform: translateY(-1px);
        transition: transform 250ms;
        color: white;
    }

    .page-nav button:active {
        transform: translateY(-2px);
        transition: transform 34ms;
    }
}