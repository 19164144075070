.winner-container {
    width: 100%;
    height: 80%;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.winner-container h2 {
    font-size: xx-large;
    padding: 1rem;
    border: 3px solid #282c34;
    border-radius: 18px;
    box-shadow: 0px 2px 0px 0px #282c34;
    background-color: transparent;
    margin: 2rem 0px 0px 0px;
}

.winner-container img {
    width: 40%;
    height: auto;
}

.winner-container Button {
    padding: 6px 10px;
    border-radius: 16px;
    border: 2px solid #198754;
    background-color: white;
    box-shadow: 0 0 8px white;
    letter-spacing: 1px;
    font-size: medium;
    transition: all .3s ease;
    margin-top: 0.8rem;
}

.winner-container Button:hover {
    background-color: #198754;
    color: white;;
}

.winner-container Button:active {
    background-color: #198754;
    color: white;
    transition: 50ms;
    transform: scale(0.95);
}

.rematchButton:disabled {
    background-color: #198754;
    color: white;
    cursor: default;
}

@media (max-width: 900px) {

    .winner-container img {
        width: 100%;
        height: auto;
    }
}