.Selection {
    position: absolute;
    left: 50%;
    top: 52%;
    transform: translate(-50%, -50%);
    padding: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 8px;
    border: 3px solid #282c34;
    border-radius: 18px;
    box-shadow: 0px 2px 0px 0px #282c34;
    background-color: transparent;
}

.gamename {
    text-align: center;
    border: 2px solid #282c34;
    border-radius: 18px;
    box-shadow: 0px 2px 0px 0px #282c34;
    background-color: transparent;
    padding: 0.2rem;
    margin: 1rem;
}

.join-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6px;
}

.Selection input {
    background-color: transparent;
    border: 2px solid #282c34;
    border-radius: 18px;
    padding: 6px 10px;
    font-size: medium;
    resize: none;
    box-sizing: border-box;
}

.Selection input:active {
    background-color: transparent;
}
.Selection input::first-letter {
    padding-left: 10px;
}

.joinCreate-Button {
    padding: 6px 10px;
    border-radius: 16px;
    border: 2px solid #198754;
    background-color: white;
    box-shadow: 0 0 8px white;
    letter-spacing: 1px;
    font-size: medium;
    transition: all .3s ease;
    color: #282c34;
}

.joinCreate-Button:hover {
    background-color: #198754;
    color: white;
    cursor: pointer;
}

.joinCreate-Button:active {
    background-color: #198754;
    color: white;
    transition: 50ms;
    transform: scale(0.95);
}

.howToPlay-Button {
    color: #282c34;
    padding: 6px 10px;
    border-radius: 16px;
    border: 2px solid #337ec7;
    background-color: transparent;
    box-shadow: 0 0 8px white;
    font-size: medium;
    transition: all .3s ease;
    color: #282c34;
    margin-top: 1rem;
}

.howToPlay-Button:hover {
    background-color: #337ec7;
    color: white;
    cursor: pointer;
}

.howToPlay-Button:active {
    background-color: #337ec7;
    color: white;
    transition: 50ms;
    transform: scale(0.95);
}

.falseCondition {
    color: red;
    position: absolute;
    right: 5%;
    bottom: 5%;
    -webkit-animation: fadeOut 4s;
    animation: fadeOut 4s;
    animation-fill-mode: forwards;
}

@-webkit-keyframes fadeOut {
    0% { opacity: 1;}
    25% { opacity: 1;}
    50% { opacity: 1;}
    75% { opacity: 0.1;}
    100% { opacity: 0;}
}

@keyframes fadeOut {
    0% { opacity: 1;}
    25% { opacity: 1;}
    50% { opacity: 1;}
    75% { opacity: 0.1;}
    100% { opacity: 0;}
}