.comingSoon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3%;
    text-align: center;
    background-color: transparent;
}

@media (max-width: 900px) {

    .comingSoon-container {
        padding: 1rem 1rem 1rem 1rem;
    }

    .comingSoon-container p {
        font-size: large;
    }
}