.gameSelection {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 5rem;
    padding: 3rem;
    justify-content: center;
    /*display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 4rem;
    */
}

.gameSelection-container h2 {
    font-size: x-large;
    text-align: center;
    margin: 2rem auto 0rem;
}

.gameButton {
    padding: 50px;
    transition: transform 0.5s;
    width: 29.296875vw; /* 450px = 29.296875vw */
    aspect-ratio: 4/3;
    /*height: 59.681697612732094vh;*/
    margin: 0 auto;
    font-size: x-large;
    border: 3px solid #282c34;
    border-radius: 18px;
    box-shadow: 0px 2px 0px 0px #282c34;
    background-color: transparent;
    color: #282c34;
}

.gameButton:hover {
    transform: scale(1.05);
    transition: transform 0.3s;
    cursor: pointer;
}

.gameButton:active {
    transform: scale(1.04);
}

@media (max-width: 900px) {
    .gameSelection {
        display: grid;
        grid-template-columns: repeat(1, auto);
        grid-gap: 4rem;
        padding: 2rem;
        justify-content: center;
        /*display: flex;
        flex-wrap: wrap;
        column-gap: 1rem;
        row-gap: 4rem;
        */
    }

    .gameSelection-container h2 {
        font-size: larger;
        margin: 2rem auto 0rem;
    }

    .gameButton {
        padding: 50px;
        transition: transform 0.5s;
        width: 59vw;
        aspect-ratio: 4/3;
        margin: 0 auto;
        font-size: larger;

        border: 3px solid #282c34;
        border-radius: 18px;
        box-shadow: 0px 2px 0px 0px #282c34;
        background-color: transparent;
    }

}
