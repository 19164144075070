.Lobby {
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 2.8vh;
    margin-bottom: 1rem;

}

.name-container, .instruction {
    border: 3px solid #282c34;
    border-radius: 18px;
    box-shadow: 0px 2px 0px 0px #282c34;
    background-color: transparent;
}

.name-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.instruction {
    padding: 8px;
    text-align: center;
}

.instruction p {
    padding: 0px 1.5rem 0px 1.5rem;
    text-align: justify;
}

.ready-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.ready-container p {
    font-size: 14px;
}

.button-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
}

.link-container {
    display: flex;
    flex-direction: row;
    gap: 6px;
}

.link-container Button {
    padding: 6px 10px;
    border-radius: 10px;
    border: 2px solid #198754;
    background-color: transparent;
    box-shadow: 0 0 8px white;
    letter-spacing: 1px;
    font-size: 14px;
    transition: all .3s ease;
}

.link-container Button:hover {
    background-color: #198754;
    color: white;
    cursor: pointer;
}

.link-container Button:active {
    background-color: #198754;
    color: white;
    transition: 50ms;
}


.ready-button {
    padding: 6px 10px;
    border-radius: 16px;
    border: 2px solid #198754;
    background-color: transparent;
    box-shadow: 0 0 8px white;
    letter-spacing: 1px;

    font-size: 14px;
    transition: all .3s ease;
}

.ready-button:hover {
    background-color: #198754;
    color: white;
    cursor: pointer;
}

.ready-button:active {
    background-color: #198754;
    color: white;
    transition: 50ms;
}

.ready-button:disabled {
    background-color: #198754;
    color: white;
    cursor: default;
}

.qr-Code {
    width: auto;
    height: auto;
    margin-bottom: 2rem;
}

.rightCondition {
    color: green;
    position: absolute;
    right: 5%;
    bottom: 5%;
    -webkit-animation: fadeOut 4s;
    animation: fadeOut 4s;
    animation-fill-mode: forwards;
}

@media (max-width: 900px) {
    .Lobby {
        width: fit-content;
        position: absolute;
        left: 50%;
        top: 57%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        gap: 1rem;
        font-size: medium;
    }

    .instruction p {
        padding: 0px 1.5rem 0px 1.5rem;
        text-align: justify;
    }

    .link-container {
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-gap: 1rem;
        padding: 1rem;
        justify-content: center;
    }

    .qr-Code {
        margin-top: 0rem;
        margin-bottom: 1rem;
    }

    .rightCondition {
        color: green;
        position: absolute;
        right: 5%;
        bottom: 0%;
        -webkit-animation: fadeOut 4s;
        animation: fadeOut 4s;
        animation-fill-mode: forwards;
    }

}
