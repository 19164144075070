.MMYBoard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1% 0 0 0;
    text-align: center;
    background-color: transparent;
}

.MMYBoard h2 {
    margin-bottom: -0.5rem;
}

.MMYButtons {
    display: grid;
    grid-template-columns: repeat(4, auto);
    gap: 1.5rem;
    padding: 2rem;
    justify-content: center;
}

.MMYButtons Button {
    width: 7rem;
    height: 7rem;
    border: 1px solid transparent;
    background-color: transparent;
    transform: scale(1);
    transform-style: preserve-3d;
    transition: transform .5s;
    box-shadow: 1px 1px 1px 0px rgba(0,0,0,.3);
    border-radius: 18px;
}

.MMYButtons Button:hover {
    cursor: pointer;
    background-color: whitesmoke;
}

.MMYButtons Button:active {
    transform: scale(0.97);
    transition: transform .2s;
}

.MMYButtons Button.flip {
    transform: rotateY(180deg);
}

.Cat {
    background-color: transparent;
    background-image: url("../resources/Memory/cat.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 90%;
}

.Cow {
    background-color: transparent;
    background-image: url("../resources/Memory/cow.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
}

.Dog {
    background-color: transparent;
    background-image: url("../resources/Memory/dog.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 72%;
}

.Elephant {
    background-color: transparent;
    background-image: url("../resources/Memory/elephant.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 98%;
}

.Monkey {
    background-color: transparent;
    background-image: url("../resources/Memory/monkey.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 98%;
}

.Sheep {
    background-color: transparent;
    background-image: url("../resources/Memory/sheep.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 85%;
}

.T-Rex {
    background-color: transparent;
    background-image: url("../resources/Memory/t-rex.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
}

.Wolf {
    background-color: transparent;
    background-image: url("../resources/Memory/wolf.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%;
}

.cover {
    background-color: transparent;
    background-image: url("../resources/Memory/cover.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 102%;
}

@media (max-width: 900px) {
    .MMYBoard {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20% 0 0 0;
        text-align: center;
        background-color: transparent;
    }

    .MMYButtons {
        display: grid;
        grid-template-columns: repeat(4, auto);
        gap: 0.6rem;
        padding: 2rem;
        justify-content: center;
    }

    .MMYButtons Button {
        width: 20vw;
        height: 20vw;
        border: 1px solid transparent;
        background-color: transparent;
        transform: scale(1);
        transform-style: preserve-3d;
        transition: transform .5s;
        box-shadow: 1px 1px 1px 0px rgba(0,0,0,.3);
    }
}